
import { defineComponent, reactive, watch, ref } from 'vue';

import { useRouter } from 'vue-router';

import Store from '@/store';

import ProjectService from '@/services/project.service';

import Layout from '@/components/Layout.vue';
import Loader from '@/components/Loader.vue';
import Input from '@/components/form/Input.vue';
import UserInput from '@/components/form/UserInput.vue';
import Editor from '@/components/form/Editor.vue';

export default defineComponent({
	components: {
		Layout,
		Loader,
		Input,
		UserInput,
		Editor,
	},

	setup() {
		const router = useRouter();

		const projects = Store.get('projects');

		const loading = ref(false);
		const titleError = ref(false);

		const form = reactive({
			title: '',
			projectManager: [] as number[],
			description: '',
		});

		watch(
			() => form.title,
			(value) => {
				Store.set('header.title', value || 'New Project');
			}
		);

		async function create() {
			if (form.title.length === 0) {
				titleError.value = true;
				return;
			}

			titleError.value = false;
			loading.value = true;

			if (!projects.value) {
				await ProjectService.get().then((response) => {
					if (response) {
						Store.set('projects', response);
					}

					loading.value = false;
					return;
				});
			}

			await ProjectService.create({
				title: form.title,
				description: form.description,
				owner_id: form.projectManager[0],
			})
				.then((response) => {
					if (response) {
						Store.set('projects', [...projects.value, response]);
						router.push({ name: 'Projects' });
					}
				})
				.finally(() => {
					loading.value = false;
				});
		}

		return {
			// data
			form,
			titleError,
			loading,

			// functions
			create,
		};
	},
});
